
export default {
    name: 'Influencer-Head',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        options: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            popup: {
                visible: false,
                type: 1
            },
            nav: {
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 返回
        handleBack() {
            const name = this.$route.name;
            if(['Influencer-Register', 'Influencer-Verifying'].includes(name)) {
                this.$router.replace({
                    name: 'Home'
                })
            } else {
                this.$router.back()
            }
        },
        // nav
        handleNav() { // 显示快捷导航
            this.popup.visible = true
        },
        jumpLink(obj) { // 快捷导航跳转
            let hash = location.href
            if(obj.title == 'YFN') {
                window.location.href = '/'
                return
            }
            if(hash.includes(obj.url)) {
                this.popup.visible = false
                return
            }
            this.$router.push({
                name: obj.url
            })
        },
        jumpIndex() { // 跳转首页
            if(!location.hash != '#/') {
                this.$router.push('/')
            }
        },
        // 设置
        handleSet() {
            this.$emit('emit-handle-set')
        },
        // 初始化
        initialSet() {
            this.nav.list = [{
                url: 'Influencer-Mine',
                title: 'Home',
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1692846702632.png'
            }, {
                url: 'Influencer-Order',
                title: 'Order',
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1692846705700.png'
            }, {
                url: 'Influencer-Earning',
                title: 'Earrings',
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1692846707650.png'
            }, {
                url: 'Influencer-Setting',
                title: 'Settings',
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1692846709442.png'
            }, {
                url: 'Home',
                title: 'YFN',
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1692846711338.png'
            }]
        }
    },
}
