
export default {
    name: 'Influencer-InfluencerCode',
    props: {},
    data() {
        return {
            email: '',
            hasRequest: false,
            countDown: {
                time: 60,
                visible: false,
            },
            forms: {}, // 注册基本信息
            form: {
                certifyCode: ''
            },
            codeTip: ''
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.handleGet()
    },
    methods: {
        // 网红注册
        registerInfluencerAjax() {
            this.$api.influencer.setRegister({
                data: JSON.stringify({
                    ...this.forms.submit,
                    ...this.form,
                    uid: this.forms.uid
                })
            }).then(response => {
                const res = response || {};
                let status = false
                if(res.code && (res.code == 500 || res.code == 'error')) {
                    status = false
                } else {
                    status = res
                }
                if(status) {
                    this.$Toast('Submitted successfully')
                    this.$fnApi.debounce(() => {
                        this.$router.push({
                            name: 'Influencer-Verifying'
                        })
                    }, 2000)
                }
            })
        },
        // 获取code接口
        getCodeAjax() {
            this.$api.influencer.getCertifyCode({
                data: JSON.stringify({
                    sendType: 1,
                    nickName: this.forms.basic.name,
                    email: this.email
                })
            }).then(response => {
                const res = response.result;
                if(response.code == 'success') {
                    // 倒计时
                    this.setCountDown()
                }
            }).catch(err => {
                this.$Toast(err.error_msg)
            })
        },
        // 获取code
        handleGet() {
            if(!this.login) {
                this.$router.push({
                    name: 'Login'
                })
                return
            }
            this.$fnApi.debounce(() => {
                this.getCodeAjax()
            }, 200)
        },
        // 倒计时
        setCountDown() {
            this.countDown.visible = true
            this.countDown.time = 60 * 1000
            setTimeout(() => {
                this.$refs.countDown.start()
            }, 10)
        },
        finish() {
            this.countDown.visible = false
        },
        // 提交
        formSubmit(e) {
            if(e.errors) return
            this.registerInfluencerAjax()
        },
        handleSubmit() {
            const ref = this.$refs;
            ref && ref.form && ref.form.submit()
        },
        // 初始化
        initialSet() {
            this.login = this.$login()
            this.email = this.$storage.get('email') || ''
            this.forms = this.$storage.get('influencer/register') || {}
        }
    },
}
