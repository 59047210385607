
export default {
    name: 'Influencer-IndexStyle',
    props: {},
    data() {
        return {
            url: 'https://cdn2.selleroa.com/yfn-upload/influencer/1686536110862.jpg',
            list: [],
            options: {},
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 初始化
        initialSet() {
            const img = [
            'https://cdn2.selleroa.com/yfn-upload/influencer/1686536289770.png',
            'https://cdn2.selleroa.com/yfn-upload/influencer/1686536309817.png',
            'https://cdn2.selleroa.com/yfn-upload/influencer/1686536326609.png',
            'https://cdn2.selleroa.com/yfn-upload/influencer/1686536336893.png',
            'https://cdn2.selleroa.com/yfn-upload/influencer/1686536346718.png',
            'https://cdn2.selleroa.com/yfn-upload/influencer/1686536359730.png',
            'https://cdn2.selleroa.com/yfn-upload/influencer/1686536375541.png',
            'https://cdn2.selleroa.com/yfn-upload/influencer/1686536385482.png',
            'https://cdn2.selleroa.com/yfn-upload/influencer/1686536395819.png'];
            this.list = []
            for(let i = 1; i < 10; i++) {
                this.list.push({
                    url: img[i-1]
                })
            }
            this.options = {
                slidesPerView: 'auto',
                spaceBetween: 10,
                freeMode: true,
                speed: 2500,
                loop: true,
                autoplay: true,
            }
        }
    },
}
