
export default {
    name: 'Influencer-Faq',
    data() {
        return {
            faq: [],
            popup: {
                visible: false,
                desc: ''
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 提示
        handleTip(obj) {
            this.popup.visible = true
            this.popup.desc = obj.desc
        },
        // set HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: 'Influencer Program',
                keywords: 'yfn, influencer',
                description: '✔Free gifted jewelry ✔10% commission ✔20% exclusive discount! Refer and get rewarded now.',
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.faq = [{
                title: 'Choose My Jewelry',
                list: [{
                    text: 'What kind of item can I choose?',
                    desc: 'Up to $500 worth of jewelry samples, depending on  your account data'
                }, {
                    text: 'How do I choose the ring size I want?',
                    desc: 'Please choose the ring size and style you need by US standard ring size.'
                }, {
                    text: 'Can I choose a customized type of product?',
                    desc: 'We highly recommend you choose our customized products. Please submit the customized information required for the products you choose to your administrator. We will promptly submit your requirements to the factory and arrange delivery for you.'
                }]
            }, {
                title: 'Logistics',
                list: [{
                    text: 'When will the product be shipped?',
                    desc: 'Our products are generally shipped within a week, and some personalized products require up to 2 weeks due to out-of-stock or special customization.'
                }, {
                    text: 'Do I need to pay for shipping?',
                    desc: 'All costs are borne by us, it is free shipping.'
                }, {
                    text: 'Do I need to pay customs duties?',
                    desc: 'We bear all costs and we will prepay most of the customs duties. There are very few orders that require the recipient to pay the customs duties. If there is a situation where you need to pay the customs duties, please communicate with us in time, and we will refund this payment.'
                }, {
                    text: 'How can I check my package and get my logistics information?',
                    desc: 'Please communicate with your administrator, if you already know your tracking number, please click https://www.17track.net/en and enter your tracking number for the inquiry!'
                }]
            }, {
                title: 'Cooperation Requirements',
                list: [{
                    text: 'What do you need to do?',
                    desc: `1. Create a short video of no less than 15s including a fit-on photo<br>
                        2. Share creative content on your social media and tag us! **MUST INCLUDE** Instagram: use hashtag # yfnjewelry, #YFNjewelrypartner and tag @yfnjewelry_official  TikTok: Tag @yfn_us_official`
                }, {
                    text: 'On which platforms do I need to publish collaboration content?',
                    desc: 'Please share your creative content on at least 1 social media platform below. Tiktok, Instagram, Youtube, Facebook, Pinterest, Personal Blog Make sure to share on more of your social platforms to gain more exposure to boost your influencer income. We will actively forward and repost high-quality content!'
                }]
            }, {
                title: 'How to apply promo information?',
                list: [{
                    text: 'How to get my exclusive promotion link?',
                    desc: 'Please register and include your exclusive 10% commission with the post link'
                }, {
                    text: 'How to get my exclusive discount code?',
                    desc: 'Please check your promo code on your page'
                }, {
                    text: 'How to generate a promotion link for a single product?',
                    desc: 'Please get your promo item link in marketing tools'
                }, {
                    text: 'How long is the validation of my code?',
                    desc: 'Our codes are generally valid for 1 year. If you still need to activate your codes after the expiration, please contact your administrator.'
                }]
            }, {
                title: 'Commission payment method',
                list: [{
                    text: 'What do you need to do?',
                    desc: 'We support Paypal transfers and if you need other types of payment methods, please communicate with your administrator.'
                }, {
                    text: 'How often is the commission paid?',
                    desc: 'We generally pay commissions to partners whose commissions exceed $20 on the 30th of the month.'
                }, {
                    text: 'What is the commission rate?',
                    desc: 'The commission rate is 10% of the value of your completed order, and we offer higher commission rates to partners who generate more sales!'
                }]
            }, {
                title: 'Long-term cooperation',
                list: [{
                    text: `How to carry out long-term/second cooperation?`,
                    desc: `YFN will invite influencers who have long-term interactions and post on social media to become YFN's New Product Experience Officer/YFN Giveaway Promotion Ambassador and get more benefits.`
                }, {
                    text: 'How to invite my friends to join YFN promotion activities?',
                    desc: `Requirements: 10k+ followers, Europe and the United States are preferred. Please send them our contact info with your name to get noted: <br><span>influencer@yfn.com</span>`
                }]
            }]
        }
    },
}
