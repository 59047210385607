
import InfluencerHead from '../components/InfluencerHead'

export default {
    name: 'Influencer-Earning',
    components: {
        InfluencerHead
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            user: {},          // 网红信息
            info: {},          // 当前卡信息
            card: {            // 卡数据
                icon: {},
                list: [],
                iconList: []
            },
            amount: {         // 用户金额
                list: []
            },
            order: {          // 用户订单
                visible: false,
                list: []
            },
            popup: {
                visible: false,
                hasRadio: false,
            },
            check: {          // 校验
                hasCheck: false,
                tip: ''
            },
            page: {
                pageNum: 1,
                pageSize: 60,
                total: 0
            },
            params: {
                accountType: '',
                account: ''
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.getUserInfoAjax()
        this.getCardInfoAjax()
        this.getAmountAjax()
    },
    methods: {
        // 获取网红信息
        getUserInfoAjax() {
            this.$api.influencer.getUserInfo().then(response => {
                const res = response || {};
                this.user = res || {}
                this.getOrderAjax()
            })
        },
        // 信用卡信息接口
        getCardInfoAjax() {
            this.$api.influencer.getCardInfo().then(response => {
                const res = response.result || [];
                this.card.list = res
                this.setCardData()
            })
        },
        // 获取网红销售金额
        getAmountAjax() {
            this.$api.influencer.getEarning().then(response => {
                const res = response.result || {};
                this.amount.list = [{
                    text: `$${res.amountPaid || 0}`,
                    title: this.$translate('Amount paid'),
                }, {
                    text: `$${res.amountPending || 0}`,
                    title: this.$translate('Amount pending'),
                }, {
                    text: `$${res.lastPayment || 0}`,
                    title: this.$translate('Amount payment'),
                }]
            })
        },
        // 获取网红销售订单
        getOrderAjax() {
            this.$api.influencer.getSettle({
                data: JSON.stringify({
                    influencerId: this.user.id,
                    pageNum: this.page.pageNum,
                    pageSize: this.page.pageSize,
                })
            }).then(response => {
                const res = response.result || {};
                if(res.rollResult) {
                    this.order.list = res.rollResult.list || []
                    this.page.total = res.rollResult.totalCount || 0
                    this.order.list.forEach(item => {
                        item.icon = this.card.iconList.filter(list => {
                            return list.type == item.accountType
                        })[0].text || ''
                    })
                }
                this.order.visible = this.order.list.length
            })
        },
        setCardData() {
            this.info = this.card.list[0] || {};
            this.card.icon = this.card.iconList.filter(item => {
                return item.type == this.info.accountType
            })[0] || {}
        },
        // 修改卡号
        setChangeCardAjax() {
            this.$api.influencer.setUpdateCard({
                data: JSON.stringify({
                    ...this.params,
                    id: this.info.id,
                })
            }, 'POST').then(response => {
                const res = response.result || {};
                this.getCardInfoAjax()
                this.popup.visible = false
            })
        },
        // 新增卡号
        setAddCardAjax() {
            this.$api.influencer.setAddCard({
                data: JSON.stringify({
                    ...this.params
                })
            }, 'POST').then(response => {
                const res = response.result || {};
                this.getCardInfoAjax()
                this.popup.visible = false
            })
        },
        // 新增信用卡方式
        checkForm() { // 校验
            this.check.tip = ''
            if(!this.params.account) {
                this.check.tip = 'Please enter PayPal email address'
            }
            if(!this.params.accountType) {
                this.check.tip = 'Please Select your Payment Mode'
            }
            this.check.hasCheck = !this.check.tip
            this.check.tip && vant.Toast(this.check.tip)
        },
        handleAccount() { // 显示弹窗
            this.popup.visible = true
            this.params.account = this.info.account
            this.params.accountType = this.card.icon.type
        },
        handleCancel() { // 关闭弹窗
            this.popup.visible = false
        },
        handleSubmit() { // 提交
            this.checkForm()
            if(!this.check.hasCheck) {
                return
            }
            if(this.info.account) {
                // 修改卡号信息
                this.setChangeCardAjax()
            } else {
                // 新增卡信息
                this.setAddCardAjax()
            }
        },
        getContainer() {
            return document.querySelector('.popup-select');
        },
        // set HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: 'Influencer Program',
                keywords: 'yfn, influencer',
                description: '✔Free gifted jewelry ✔10% commission ✔20% exclusive discount! Refer and get rewarded now.',
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.card.iconList = [{
                type: 1,
                value: 1,
                text: 'Paypal',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1686637847489.png'
            }, {
                type: 2,
                value: 2,
                text: 'Zelle',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1686637862808.png'
            }, {
                type: 3,
                value: 3,
                text: 'Payoneer',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1686637877322.png'
            }]
        }
    },
}
