
export default {
    name: 'IndexWhy',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
