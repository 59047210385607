
export default {
    name: 'influencer-IndexBanner',
    props: {
        login: Boolean,
        apply: Object
    },
    data() {
        return {
            url: {
                img1: 'https://cdn2.selleroa.com/yfn-upload/review/1711613065764.png',
                img2: 'https://cdn2.selleroa.com/yfn-upload/influencer/1686535399071.jpg'
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        jumpRegister() {
            if(!this.login) {
                this.$router.push({
                    name: 'Login'
                })
                return
            }
            if(this.apply.status == 3) {
                this.$router.push({
                    name: 'Influencer-Register'
                })
            } else {
                this.$router.push({
                    name: 'Influencer-Verifying'
                })
            }
        }
    },
}
