
export default {
    name: 'Influencer-IndexFaq',
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        jumpFaq() {
            this.$router.push({
                name: 'Influencer-Faq'
            })
        }
    },
}
