
import IndexBanner from './Index/IndexBanner'
import IndexStyle from './Index/IndexStyle'
import IndexFaq from './Index/IndexFaq'
import IndexWhy from './Index/IndexWhy.vue'

export default {
    name: 'Influencer-Index',
    components: {
        IndexBanner,
        IndexWhy,
        IndexStyle,
        IndexFaq
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            skeleton: true,
            status: false,
            url: require('~/assets/images/vip.png'),
            user: {},
            apply: {         // 网红申请状态
                status: 0,   // 0 等待审核 1 审核通过 2 失败 3 未申请
                url: ''
            },
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 获取网红状态
        getApplyStatusAjax() {
            this.$api.influencer.getApplyStatus().then(response => {
                const res = response;
                let url = '';
                if(Object.prototype.toString.call(res) === '[object Object]') {
                    if(res.code && (res.code == 500 || res.code == 'error')) {
                        this.status = 2
                    } else {
                        this.status = res.result
                    }
                } else {
                    this.status = res
                }
                if(this.status == 1) {
                    url = 'Influencer-Mine'
                } else if(this.status == 3) {
                    url = 'Influencer-Register'
                } else {
                    url = 'Influencer-Verifying'
                }
                this.apply.url = url
                const hasApplyStatus = this.$storage.get('influencer/hasApplyStatus');
                if(!hasApplyStatus) {
                    this.$storage.set('influencer/hasApplyStatus', true)
                    this.$router.push({
                        name: url
                    })
                } else {
                    this.skeleton = false
                }
            })
        },
        // set HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('YFN Influencer Program'),
                keywords: 'yfn, influencer',
                description: '✔Free gifted jewelry ✔10% commission ✔20% exclusive discount! Refer and get rewarded now.',
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
            if(this.hasLogin) {
                this.getApplyStatusAjax()
            } else {
                let clear = setTimeout(() => {
                    this.skeleton = false
                    clearTimeout(clear)
                }, 500)
            }
        },
        jumpLogin() {
            this.$router.push({
                name: 'Login'
            })
        },
        jumpMine() {
            if(this.hasLogin) {
                this.$router.push({
                    name: this.apply.url
                })
            } else {
                this.$router.push({
                    name: 'Login'
                })
            }
        }
    },
}
